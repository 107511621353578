import React from "react";
import { Col } from "react-bootstrap";

import styled from "styled-components";

const MyColumn = styled(Col)`
  overflow: hidden;

  .gatsby-resp-image-wrapper {
    width: 100%;
    max-width: none !important;
  }
`;

const Column = ({ children, size, noMarginBottom }) => (
  <MyColumn
    lg={size ? size * 2 : true}
    md={12}
    style={{
      marginBottom: noMarginBottom ? 0 : "75px",
    }}
  >
    {children}
  </MyColumn>
);

export default Column;
