import { graphql } from "gatsby";
import React from "react";
import "@Styles/main.scss";

import { Container, Row, Col } from "react-bootstrap";

import ProjectHeader from "@Components/project/ProjectHeader";
import ProjectHeadImage from "@Components/project/ProjectHeadImage";

import styled from "styled-components";
import { Theme } from "@Styles/Theme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Column from "@Components/project/Column";
import { Title } from "@Components/project/Title";

import SEO from "@Components/SEO";

const TopBtn = styled.div`
  position: fixed;
  bottom: 50px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5000;
  color: ${Theme.colors.red};
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  cursor: pointer;
  letter-spacing: -1px;

  &:hover {
    color: ${Theme.colors.darkRed};
  }
`;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      skills
      slug
      coverImage {
        fluid {
          src
          sizes
          aspectRatio
          srcSet
        }
      }
      thumbnail {
        fluid {
          srcSet
          src
          sizes
          aspectRatio
        }
      }
      content {
        internal {
          content
        }
      }
    }
  }
`;

const Project = ({ data }) => {
  const [showButton, setShowButton] = React.useState(false);

  const displayButton = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", displayButton);
    return () => {
      window.removeEventListener("scroll", displayButton);
    };
  }, []);

  const content = JSON.parse(data.contentfulProject.content.internal.content);
  console.log(content);

  const renderContent = (content) => {
    if (content && content.data) {
      if (content.contentType === "text" && content.data.element) {
        switch (content.data.element) {
          case "h1":
            return (
              <Title.LevelOne
                bold={content.data.isBold}
                color={content.data.color}
                align={content.data.align}
              >
                {content.data.text}
              </Title.LevelOne>
            );
          case "h2":
            return (
              <Title.LevelTwo
                bold={content.data.isBold}
                color={content.data.color}
                align={content.data.align}
              >
                {content.data.text}
              </Title.LevelTwo>
            );
          case "h3":
            return (
              <Title.LevelThree
                bold={content.data.isBold}
                color={content.data.color}
                align={content.data.align}
              >
                {content.data.text}
              </Title.LevelThree>
            );
          case "p":
            return (
              <p
                style={{
                  color: content.data.color,
                  textAlign: content.data.align,
                  fontWeight: content.data.isBold ? 900 : "regular",
                  whiteSpace: "pre-wrap",
                }}
              >
                {content.data.text}
              </p>
            );
        }
      } else if (content.contentType === "media") {
        return (
          <img
            src={`https:${content.data.fields.file["en-US"].url}`}
            alt={content.data.fields.title}
            style={{
              width: "100%",
            }}
          />
        );
      }
    }
  };

  return (
    <div
      style={{
        paddingBottom: "100px",
      }}
    >
      <SEO title={data.contentfulProject.title} />
      <ProjectHeader />
      {data.contentfulProject.coverImage && (
        <ProjectHeadImage
          headImgSrc={data.contentfulProject.coverImage.fluid}
        />
      )}
      <Container>
        <Row>
          <Col
            md={{ span: 8, offset: 2 }}
            xl={{ span: 10, offset: 1 }}
            style={{ paddingTop: 200 }}
          >
            {content.sections.map((section) => {
              return (
                <Row>
                  {Object.values(section.columns).map((column) => {
                    return (
                      <Column size={column.colSize}>
                        {Object.values(column.contents).map((content) => {
                          return renderContent(content);
                        })}
                      </Column>
                    );
                  })}
                </Row>
              );
            })}
          </Col>
        </Row>
      </Container>
      {showButton && (
        <TopBtn onClick={scrollTop}>
          <FontAwesomeIcon icon={faAngleUp} className="icon" />
          Haut de page
        </TopBtn>
      )}
    </div>
  );
};

export default Project;
