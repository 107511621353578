import React from "react";
import { Theme } from "@Styles/Theme";

const LevelOne = ({
  children,
  italic,
  uppercase,
  color,
  align = "left",
  bold,
}) => (
  <h1
    style={{
      textTransform: uppercase ? "uppercase" : "none",
      fontStyle: italic ? "italic" : "none",
      color: color ? color : Theme.colors.red,
      textAlign: align,
      fontWeight: bold ? 900 : "regular",
      whiteSpace: "pre-wrap",
    }}
  >
    {children}
  </h1>
);

const LevelTwo = ({
  children,
  italic,
  uppercase,
  color,
  align = "left",
  bold,
}) => (
  <h3
    style={{
      textTransform: uppercase ? "uppercase" : "none",
      fontStyle: italic ? "italic" : "none",
      color: color ? color : Theme.colors.red,
      textAlign: align,
      fontWeight: bold ? 900 : "regular",
      whiteSpace: "pre-wrap",
    }}
  >
    {children}
  </h3>
);

const LevelThree = ({
  children,
  italic,
  uppercase,
  color,
  align = "left",
  bold,
}) => (
  <h5
    style={{
      textTransform: uppercase ? "uppercase" : "none",
      fontStyle: italic ? "italic" : "none",
      color: color ? color : Theme.colors.red,
      textAlign: align,
      fontWeight: bold ? 900 : "regular",
      whiteSpace: "pre-wrap",
    }}
  >
    {children}
  </h5>
);

export const Title = {
  LevelOne,
  LevelTwo,
  LevelThree,
};
