import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Theme } from "@Styles/Theme"
import { initial } from "lodash"

const Insider = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ThemedLink = styled(Link)`
  font-weight: 600;
  color: ${Theme.colors.red};
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 900;

  &:hover{
    text-decoration: none;
    color: ${Theme.colors.blue};
  }
`

export default function ProjectHeader() {

  let logoData = useStaticQuery(graphql`
    query Logo {
      file(relativePath: {eq: "logos/logo_loony_rouge.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
  )

  const [isMobile, setIsMobile] = React.useState(false)

  function init() {
    if (window.innerWidth < 1025) {
      setIsMobile(true)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <div style={{ width: "100vw", height: "100px", position: "fixed", zIndex: 1000, backgroundColor: "#fff", marginBottom: "100px", boxShadow: "0 4px 10px rgba(0,0,0,0.1)" }}>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
            <Insider>
              <Img
                fluid={logoData.file.childImageSharp.fluid}
                style={{
                  width: "200px",
                  position: "relative",
                  left: "-24px"
                }}
                alt="Logo Loony Rouge"
              />
              <ThemedLink to={isMobile ? "/" : "/projects"}>Retour</ThemedLink>
            </Insider>
          </Col>
        </Row>
      </Container>
    </div>
  )
}