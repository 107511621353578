import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"

import Img from "gatsby-image"

const ThemedContainer = styled(Container)`
  width: 100%;
  height: auto;
  background-color: #ECECEC;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10rem;
  padding: 0;
  position: relative;
  top: 100px;
`

export default function ProjectHeader({
  headImgSrc
}) {

  return (
    <ThemedContainer fluid>
      <Img
        fluid={headImgSrc}
        style={{
          minWidth: "100%",
          minHeight: "100%"
        }}
      />
    </ThemedContainer>
  )
}